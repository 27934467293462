@import '../../style/themes/index';
@import '../../style/mixins/index';

@space-prefix-cls: ~'@{ant-prefix}-space';
@space-item-prefix-cls: ~'@{ant-prefix}-space-item';

.@{space-prefix-cls} {
  display: inline-flex;
  &-vertical {
    flex-direction: column;
  }

  &-align {
    &-center {
      align-items: center;
    }
    &-start {
      align-items: flex-start;
    }
    &-end {
      align-items: flex-end;
    }
    &-baseline {
      align-items: baseline;
    }
  }
}

.@{space-item-prefix-cls} {
  &:empty {
    display: none;
  }
}

@import './rtl';

@body-background: #FFFFFF;@text-color: #000000;@font-size-base: 16px;@font-family: 'SF Pro Display', sans-serif;@secondary-color: #00FF00;@primary-bg-color: #FF0000;@primary-color: #3D4CC1;@border-radius-base: 4px;@input-height-base: 45px;@input-padding-horizontal-base: 11px;@input-placeholder-color: #9A9A9A;@input-color: #000000;@input-border-color: #D9D9D9;@input-hover-border-color: #BFBFBF;@input-outline-offset: none;@btn-default-color: #3D4CC1;@btn-default-border: #3D4CC1;@btn-padding-horizontal-base: 16px;@btn-text-shadow: none;@btn-border-radius-base: 8px;@btn-height-base: 45px;@checkbox-size: 18px;@checkbox-color: #3D4CC1;@menu-item-active-bg: #FFFFFF;@select-item-selected-bg: #F7FAFF;@select-item-active-bg: #F7FAFF;@table-body-sort-bg: #FFFFFF;@table-header-sort-bg: #FAFAFA;@rate-star-bg: #d0d0d0;